/* eslint-disable react/require-default-props */
import Router from 'next/router';
import Head from 'next/head';
import NProgress from 'nprogress';

interface LayoutBaseProps {
  title?: string;
  description?: string;
  children: React.ReactNode;
}

export default function LayoutBase({ children, title, description }: LayoutBaseProps): JSX.Element {
  if (Router.events) {
    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => NProgress.done());
    Router.events.on('routeChangeError', () => NProgress.done());
  }

  const htmlTitle = title ? `${title} - Attendly` : 'Attendly';

  return (
    <>
      <Head>
        <title>{htmlTitle}</title>
        { description && <meta name="description" content={description} /> }
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
        {children}
      </main>
    </>
  );
}
